<template>
  <div>
    <el-row>
      <el-col :span="16"
        ><div class="grid-content">
          <ROSVG />
        </div>
      </el-col>
      <el-col :span="8"
        ><div class="grid-content">
          <BestCitiesOverall :cities="cities" />
        </div>
      </el-col>
    </el-row>
    <el-row :gutter="40">
      <el-col :span="12"
        ><div class="grid-content">
          <MostRatedCities :cities="cities" />
        </div>
      </el-col>
      <el-col :span="12"
        ><div class="grid-content">
          <LatestRatedCities :cities="cities" />
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import BestCitiesOverall from "@/components/BestCitiesOverall.vue";
import MostRatedCities from "@/components/MostRatedCities.vue";
import LatestRatedCities from "@/components/LatestRatedCities.vue";

import ROSVG from "@/components/ROSVG.vue";
export default defineComponent({
  name: "HomeView",
  data() {
    return {
      cities: [
        {
          date: "2016-05-03",
          name: "Tom",
          address: "No. 189, Grove St, Los Angeles",
        },
        {
          date: "2016-05-02",
          name: "Tom",
          address: "No. 189, Grove St, Los Angeles",
        },
        {
          date: "2016-05-04",
          name: "Tom",
          address: "No. 189, Grove St, Los Angeles",
        },
        {
          date: "2016-05-01",
          name: "Tom",
          address: "No. 189, Grove St, Los Angeles",
        },
      ],
    };
  },
  components: {
    ROSVG,
    BestCitiesOverall,
    MostRatedCities,
    LatestRatedCities,
  },
});
</script>
<style>
.el-row {
  margin-bottom: 20px;
}
.el-row:last-child {
  margin-bottom: 0;
}
.el-col {
  border-radius: 4px;
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
}
</style>
