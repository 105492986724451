import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "grid-content" }
const _hoisted_2 = { class: "grid-content" }
const _hoisted_3 = { class: "grid-content" }
const _hoisted_4 = { class: "grid-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ROSVG = _resolveComponent("ROSVG")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_BestCitiesOverall = _resolveComponent("BestCitiesOverall")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_MostRatedCities = _resolveComponent("MostRatedCities")!
  const _component_LatestRatedCities = _resolveComponent("LatestRatedCities")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_el_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_el_col, { span: 16 }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createVNode(_component_ROSVG)
            ])
          ]),
          _: 1
        }),
        _createVNode(_component_el_col, { span: 8 }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_BestCitiesOverall, { cities: _ctx.cities }, null, 8, ["cities"])
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_el_row, { gutter: 40 }, {
      default: _withCtx(() => [
        _createVNode(_component_el_col, { span: 12 }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_MostRatedCities, { cities: _ctx.cities }, null, 8, ["cities"])
            ])
          ]),
          _: 1
        }),
        _createVNode(_component_el_col, { span: 12 }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_LatestRatedCities, { cities: _ctx.cities }, null, 8, ["cities"])
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}