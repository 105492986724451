<template>
  <img src="@/assets/romania.svg" alt="Romania" />
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "ROSVG",
});
</script>
