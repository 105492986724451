import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import HomeView from "../views/HomeView.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/cookies",
    name: "Cookies",
    component: () => import("@/views/CookiesView.vue"),
    // prettyName: "Politica de COOKIES",
  },
  {
    path: "/privacy",
    name: "Privacy",
    component: () => import("@/views/PrivacyView.vue"),
    // prettyName: "Politica de confidențialitate",
  },
  {
    path: "/content-review",
    name: "ContentReview",
    component: () => import("@/views/ContentReviewView.vue"),
    // prettyName: "Politica de conținut și reguli de aprobare",
  },
  {
    path: "/despre",
    name: "Despre",
    component: () => import("@/views/AboutView.vue"),
    // order: 3,
    // prettyName: "Despre Noi",
  },
  {
    path: "/404",
    name: "Page-Not-found",
    component: () => import("@/views/PageNotFoundView.vue"),
    // prettyName: "Pagina nu a fost gasită",
  },
  {
    path: "/contact",
    name: "Contact",
    component: () => import("@/views/ContactView.vue"),
    // order: 4,
    // prettyName: "Contact",
  },
  {
    path: "/criterii",
    name: "Criterii",
    component: () => import("@/views/AboutView.vue"),
    // order: 2,
    // prettyName: "Criterii",
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/AboutView.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
