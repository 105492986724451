<template>
  <div>
    <h2>Most rated cities</h2>
    <el-table :data="cities" style="width: 100%">
      <el-table-column prop="date" label="Date" width="180" />
      <el-table-column prop="name" label="Name" width="180" />
      <el-table-column prop="address" label="Address" />
    </el-table>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    cities: {
      type: Array,
      required: true,
    },
  },
  name: "MostRatedCities",
});
</script>
